import React, { Component } from "react";
import { Link } from "gatsby";

class BenefitText4 extends Component {
  render () {
    return (
      <div {...this.props}>
        <h4 className="has-text-weight-bold">
          <span className="highlightable"><span></span></span>
        </h4>
        <br/>
        <h2>Revitalize your routine and relieve those areas that need it most.</h2>
        <p>Our age defying and pain relieving formulations are anything but ordinary. We use an immersive blend of natural ingredients in combination with Smile CBD, leaving your skin supple and smooth or keeping your body pain free.</p>
        <br />

        <Link to="/shop/rejuvenate">
          <button className="is-uppercase button is-primary is-fullwidth-mobile">SHOP REJUVENATE</button>
        </Link>
      </div>
    );
  }
}

export default BenefitText4;