import Testimonials from "../testimonials";
import TestimonialsMobile from "../testimonials-mobile";
import React, { Component } from "react";

const quotes = ["The unwind mint oil drops have been a miracle for me! I take two droppers 20-30 minutes before I sleep every night now. Sleep has never felt so good. French Vanilla is my favorite!",
"A balanced, stable mood plays a key role in becoming your best self. Smile’s Vegan Multivitamin Gummies are an effective, natural remedy for every day balance so you can move through the day with confidence.",
"Since incorporating Smile’s Mighty Mango tincture into my morning routine, I genuinely feel clear and in the moment with each of my patients. The positive impact their 1000mg oil has had on my personal and professional life is unlike any other supplement I’ve tried.",
"I don't think anybody wants to rely on something as heavy duty as prescription medication to relax during the day. I was looking for something that was more natural that would actually work for me, so this is just amazing."];

class TestimonialsSection extends Component {
  render () {
    return (
      <section className="section is-small has-white-background is-overflow-hidden is-relative" style={{paddingBottom:'4rem'}}>
        <div className="container">
          <Testimonials />
          <TestimonialsMobile quotes={quotes}/>
        </div>
      </section>
    );
  }
}

export default TestimonialsSection
