import { isBrowser } from "react-device-detect";
import AnimatedLines from "../animatedLines";
import { Link } from "gatsby";
import Benefits from "../benefits";
import React, { Component } from "react";
import "./smile-promise-section.scss";

class SmilePromiseSection extends Component {
  render() {
    return (
      <section
        className="section is-small has-white-background is-relative smile-promise-secion">
        <div className="section-bg">
          <AnimatedLines
            className="lines-01"
            style={{
              transform: "scaleX(1) rotate(10deg)",
              position: "absolute",
              top: isBrowser ? "50%" : "65%",
              left: isBrowser ? "-10%" : "-100%",
              zIndex: "20",
              width: "592px",
            }}
          />
          <AnimatedLines
            className="lines-02"
            style={{
              transform: "rotate(0deg)",
              position: "absolute",
              top: isBrowser ? "5%" : "-10%",
              right: isBrowser ? "-18%" : "-130%",
              zIndex: "1",
              width: "654px",
            }}
          />
        </div>
        <div className="container">
          <div className="columns is-centered is-multiline smile-promise-secion-first">
            <div className="column is-6 has-text-centered">
              <h1
                className="is-h1-desktop-mobile"
                style={{ paddingBottom: "20px" }}>
                The Smile Promise
              </h1>
              <h4>
                From pure, ethically sourced ingredients to a responsible final
                product, Smile holds itself to the highest standard, in every
                way. Every Smile product is made from 100% organic hemp grown in
                the USA. No gimmicks, no additives, no BS—just the good stuff.
                We promise.
              </h4>
              <br />
              <br />

              <Link to="/ingredients" style={{ zIndex: "2" }}>
                <button className="button is-secondary">
                  MEET OUR INGREDIENTS
                </button>
              </Link>
            </div>
          </div>

          <Benefits />
        </div>
      </section>
    );
  }
}

export default SmilePromiseSection;
