import React, { Component, Fragment } from "react";

export default class ShadowImg extends Component {
  render () {
    return (
      <Fragment>

        <img id={this.props.id + "-shadow"}
          data-src={this.props.shadowSrc}
          className={`shadow-img shadow lazyload ${this.props.className}`}
          style={this.props.style}
          alt="" />

        <img id={this.props.id}
          data-src={this.props.src}
          className={`shadow-img lazyload ${this.props.className}`}
          style={this.props.style}
          alt="" />

      </Fragment>
    );
  }
}
