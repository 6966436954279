import { isBrowser } from "react-device-detect";
import React, { Component } from "react";
import { Link } from "gatsby";
import "./shop-category-section.scss";

export default class ShopCategorySection extends Component {
  render() {
    return (
      <section
        className="section has-white-background is-relative"
        id="shop-category-section"
        style={{
          paddingTop: isBrowser ? "110px" : "70px",
        }}
      >
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4 has-text-centered">
              <Link to="/products/cbd-vegan-gummies-passion-fruit-300mg" style={{ textDecoration: "none" }}>
                <figure className="image is-3by2">
                  <img
                    data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2020/03/gummies_2x.jpg"
                    alt=""
                    className="lazyload"
                    style={{ objectFit: "cover" }}
                  />
                </figure>
                <br />
                <h2 className="has-text-black">Gummies</h2>
                <p className="has-text-black" style={{ marginTop: "10px" }}>
                  Not your mother’s multivitamin. Tasty tropical chews combine
                  CBD isolate with a blend of nine powerhouse nutrients for
                  sustained energy and a calm, balanced mood.
                </p>
              </Link>
              <div className="shop-button-section">
                <a href="/products/cbd-vegan-gummies-passion-fruit-300mg">
                  <button className="is-uppercase button is-fullwidth-mobile shop-button">SHOP GUMMIES</button>
                </a>
              </div>
            </div>
            <div className="column is-4 has-text-centered">
              <Link to="/products/cbd-oil-mighty-mango" style={{ textDecoration: "none" }}>
                <figure className="image is-3by2">
                  <img
                    data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2020/03/tinctures_2x.jpg"
                    alt=""
                    className="lazyload"
                    style={{ objectFit: "cover" }}
                  />
                </figure>
                <br />
                <h2 className="has-text-black">Oil Drops</h2>
                <p className="has-text-black" style={{ marginTop: "10px" }}>
                  Potent oil drops combine hemp seed oil, MCT oil & select
                  wellness supplements for powerful, fast-acting effects:
                  whether you’re looking to increase focus or calm your mind.
                </p>
              </Link>
              <div className="shop-button-section">
                <a href="/products/cbd-oil-mighty-mango">
                  <button className="is-uppercase button is-fullwidth-mobile shop-button">SHOP OIL DROPS</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
