import React, { Component } from "react";
import InstaFeed from "../instaFeed";

class InstaFeedSection extends Component {
  render () {
    return (
      <section className="section is-medium is-relative has-white-background insta-feed">
        <InstaFeed />
      </section>
    );
  }
}

export default InstaFeedSection
