import React, { Component } from "react";
import yotpo from "../../../lib/yotpo";
import { Link } from "gatsby";

export default class ShopSectionHero1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avg_rating: 0
    };
  }
  componentDidMount() {
    yotpo.getAllSiteReviews(this.props.products).then(reviewsData => {
      this.setState({
        avg_rating: reviewsData.avg_rating
      });
    });
  }
  render () {
    return (
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-5 has-text-light">
                  <h1 className="has-text-light">Feel better, <br/>or it’s on us.</h1>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4 has-text-light">
                  <h4>If you don’t love Smile CBD within the first three days, return the rest for a full, no-hassle refund.</h4>
                  <br />
                  <Link to="/shop" className="button is-primary">
                    TRY IT RISK FREE
                  </Link>
                  <br />
                  {this.state.avg_rating > 4.5 ? (
                    <div
                      className="cursor-pointer all-reviews"
                      aria-label="Show reviews">
                          <a
                            href="/products/cbd-vegan-gummies-fresh-watermelon-300mg#review"
                            className="all-reviews-link">
                            <span className="rating-text">
                              Rated {this.state.avg_rating.toFixed(1)}
                            </span>
                            &nbsp;<span>out of 5 based on</span>
                            <span className="has-text-weight-bold">
                              {" "}
                              1000+ Verified Reviews on{" "}
                              <img
                                src={require("../../../images/yotpo-logo-v2.svg")}
                                alt="Yotpo"
                              />
                            </span>
                          </a>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
    );
  }
}
