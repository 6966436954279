import React, { Component } from "react";
import Slider from "react-slick";
import { isBrowser } from "react-device-detect";
import "./testimonials.scss";
import WatchedElement from "./watchedElement";
import { Link } from "gatsby";

const Testimonial = (props) => {
  return (
    <div className="testimonial has-text-white">
      <div className="columns is-vcentered is-centered has-text-centered-mobile">

      
        <div className="column is-4">
          <div className="columns is-mobile is-multiline">
            <div className="column is-4-mobile" style={{position: "relative"}}>
              <div className="name-and-role">
                <a href={props.to} target="_blank" rel="noopener noreferrer">
                  <br />
                  <p className="testimonial-name has-text-weight-bold has-text-black has-text-left">
                    {props.name} {typeof props.instagram != "undefined"? "| " + props.instagram : false}</p>
                  <p className="testimonial-name has-text-black has-text-left">{props.role}</p>
                </a>
              </div>
            </div>
            <div className="column is-8-mobile">
                <figure className="image testimonial-image-background">
                  <img data-src={`${props.imgUrl}`} alt="testimonial" className="lazyload testimonial-image" />
                </figure>
            </div>
          </div>
          <Link to={props.product}>
              <button className="button is-secondary favourites-button">
                {props.button}
              </button>
          </Link>
        </div>
        
      </div>
    </div>

  );
};
const quotes = ["The unwind mint oil drops have been a miracle for me! I take two droppers 20-30 minutes before I sleep every night now. Sleep has never felt so good. French Vanilla is my favorite!",
"A balanced, stable mood plays a key role in becoming your best self. Smile’s Vegan Multivitamin Gummies are an effective, natural remedy for every day balance so you can move through the day with confidence.",
"I don't think anybody wants to rely on something as heavy duty as prescription medication to relax during the day. I was looking for something that was more natural that would actually work for me, so this is just amazing.",
"Since incorporating Smile’s Mighty Mango tincture into my morning routine, I genuinely feel clear and in the moment with each of my patients. The positive impact their 1000mg oil has had on my personal and professional life is unlike any other supplement I’ve tried."];

class TestimonialsMobile extends Component {
  constructor (props) {
    super(props);
    this.state = {
      autoplay: false,
      quote: quotes[0]       
    };
  }

  testimonialsSliderChange(index) {
    this.setState({ quote: quotes[index]});
  }
  render () {
    const handleEnterViewport = () => {
      this.slider.slickPlay();
    };

    const SamplePrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (<div className={className} style={{
        ...style,
        display: "block"
      }} onClick={onClick} />);
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 4000,
      autoplay: true,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: this.testimonialsSliderChange.bind(this),
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      <div className="testimoonials-mobile">
      <WatchedElement handleEnterViewportScrollDown={handleEnterViewport}>
            <div className="testimonial-container">
              <div>
                <img src={require("../images/stars.svg")} style={{
                  margin: '20px 0 25px'
                }} alt="" />
              </div>
              <h4 className="has-text-black has-text-left">"{this.state.quote}"</h4>
              
            </div>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <Testimonial imgUrl="https://reasontosmile.wpengine.com/wp-content/uploads/2021/01/fatherkels_2x.jpg" name="Kelsey Calemin" instagram="@fatherkels" role="Influencer" to="https://instagram.com/fatherkels" quote="The unwind mint oil drops have been a miracle for me! I take two droppers 20-30 minutes before I sleep every night now. Sleep has never felt so good. French Vanilla is my favorite!" button='shop Kelsey’s favorites' product='/products/cbd-oil-mint-bliss'/>
          <Testimonial imgUrl="https://reasontosmile.wpengine.com/wp-content/uploads/2021/01/rebbeca_2x.jpg" role="Women Empowerment Mentor" name='Rebecca Ruiz' quote='A balanced, stable mood plays a key role in becoming your best self. Smile’s Vegan Multivitamin Gummies are an effective, natural remedy for every day balance so you can move through the day with confidence.' button='shop Rebecca’s favorites' product='/products/cbd-vegan-gummies-fresh-watermelon-300mg'/>
          <Testimonial imgUrl="https://reasontosmile.wpengine.com/wp-content/uploads/2021/01/theangelinos_2x.jpg" role="Influencers" to="https://www.instagram.com/theangelinos_" name="Ariana & Hannah" instagram="@theangelinos_" quote="I don't think anybody wants to rely on something as heavy duty as prescription medication to relax during the day. I was looking for something that was more natural that would actually work for me, so this is just amazing." button='shop ANGELINOS favorites' product='/products/cbd-oil-french-vanilla'/>
          <Testimonial imgUrl="https://reasontosmile.wpengine.com/wp-content/uploads/2021/01/mehru_2x.jpg" role="Dentist, MD" name='Mehru Bhatia' quote="Since incorporating Smile’s Mighty Mango tincture into my morning routine, I genuinely feel clear and in the moment with each of my patients. The positive impact their 1000mg oil has had on my personal and professional life is unlike any other supplement I’ve tried." button='shop Mehru’s favorites' product='/products/cbd-oil-mighty-mango'/>
        </Slider>
      </WatchedElement>
      </div>  
    );
  }
}

export default TestimonialsMobile;