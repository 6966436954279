import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { styleString } from "../../../lib/helpers";
import SvgBlobsStates from "./svg-blobs-states";

export default class SvgBlobs extends Component {

  componentDidMount () {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    const { svgBlobsStates } = this.props;
    svgBlobsStates.init();

    const happyBlob = document.querySelector("svg#shop-section-bg-svg path#shop-section-happy-blob");
    if (happyBlob) {
      happyBlob.setAttribute("style", styleString(svgBlobsStates.happy_blob_style));
    }

    const sunnyBlob = document.querySelector("svg#shop-section-bg-svg path#shop-section-sunny-blob");
    if (sunnyBlob) {
      sunnyBlob.setAttribute("style", styleString(svgBlobsStates.sunny_blob_style));
    }
  };

  render () {
    const { svgBlobsStates } = this.props;

    return (
      <Fragment>
        <svg id="shop-section-bg-svg">
          <path
            id="shop-section-happy-blob"
            d="M455.337848,809.425479 C83.9586611,648.015052 -60.2444283,465.616272 22.7285798,262.22914 C147.188092,-42.8515593 667.518824,-16.6161184 875.951019,24.6110031 C1084.38321,65.8381245 1552.23102,206.759922 1666.19395,305.705013 C1780.15687,404.650105 1825.14224,493.10102 1825.14224,562.06275 C1825.14224,631.024481 1780.42363,801.859425 1520.74126,887.382218 C1329.55346,950.347276 945.17975,1022.32268 455.337848,809.425479 Z"
            fill="#DE4826"
            fillRule="evenodd"
            style={svgBlobsStates.happy_blob_style}
          />
          <path
            id="shop-section-sunny-blob"
            d={svgBlobsStates.sunny_blob_states.state_1.d}
            fill="#F6BD17"
            fillRule="evenodd"
            style={svgBlobsStates.sunny_blob_style}
          />
        </svg>
        {/*<div id="home-svg-background-ratio" />*/}
        {/*<div id="debug-center" />*/}
      </Fragment>
    );
  }

}

SvgBlobs.propTypes = {
  svgBlobsStates: PropTypes.instanceOf(SvgBlobsStates).isRequired
};
