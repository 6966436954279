import React, { Component } from "react";
import DirectAnimatedDivider from "../directAnimatedDivider";
import VariableHighlightedLabel from "../variableAnimatedDivider";
import "./logos-section.scss";
class LogosSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount () {
  }
  render () {
    return (
      <section className="section is-small is-relative press-section">
        <div className="container">
        <div className="columns is-vcentered is-small press-section-desktop is-mobile is-multiline">
          <div className="column has-text-centered is-4-mobile is-2-desktop is-2-tablet">
            <img src={require("../../images/press/Forbes.svg")} alt="" />
          </div>
          <div className="column has-text-centered is-4-mobile is-2-desktop is-2-tablet">
            <img src={require("../../images/press/business-insider.svg")} alt=""/>
          </div>
          <div className="column has-text-centered is-4-mobile is-2-desktop is-2-tablet">
            <img src={require("../../images/press/iHeart.svg")} alt="" />
          </div>
        </div>
        <div className="columns is-vcentered is-small press-section-desktop is-mobile is-multiline">
          <div className="column has-text-centered is-6-mobile is-1-desktop is-1-tablet is-hidden-mobile">
          </div>
          <div className="column has-text-centered is-4-mobile is-2-desktop is-2-tablet">
            <img src={require("../../images/press/NBC.svg")} alt="" />
          </div>
          <div className="column has-text-centered is-4-mobile is-2-desktop is-2-tablet">
            <img src={require("../../images/press/NY-mag.svg")} alt="" />
          </div>
          <div className="column has-text-centered is-4-mobile is-2-desktop is-2-tablet">
            <img src={require("../../images/press/rollingstone.png")} alt="" />
          </div>
        </div>
        </div>
      </section>
    );
  }
}

export default LogosSection;
