import React, { Component } from "react";
import { Link } from "gatsby";

class BenefitText3 extends Component {
  render () {
    return (
      <div {...this.props}>
        <h4 className="has-text-weight-bold">
          <span className="highlightable"><span>Melatonin + 5-HTP</span></span>
        </h4>
        <br/>
        <h2>A formula that calms the mind & promotes peaceful sleep</h2>
        <p>We start with 100% organic CBD oil, then add 5-HTP & melatonin to relax your body and ease into a chiller headspace</p>
        <br />
        <Link to="/shop/unwind">
          <button className="is-uppercase button is-primary is-fullwidth-mobile">SHOP UNWIND</button>
        </Link>
      </div>
    );
  }
}

export default BenefitText3;
