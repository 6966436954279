import React, { Component } from "react";
import { Link } from "gatsby";
import "./happiness-inside-section.scss";

export default class HappinessInsideSection extends Component {
  render () {
    return (
      <section className="happiness-inside-section section has-white-background is-relative">




        <div className="container">
        <div className="columns is-vcentered is-centered is-reversed-mobile">
            <div className="column has-text-centered-mobile">
              <h1 className="is-h1-desktop-mobile">Happiness Inside</h1>
              <h4>Smiles are contagious. Smiles have the power to change the world. Our CBD products are designed to help you tune into your best self, so you can be here & now… happily.</h4>
              <br />
              <Link to="/story">
              <button className="button is-secondary">SEE OUR STORY</button>
              </Link>
            </div>
            <div className="column is-relative happiness-inside-image is-6 is-offset-1" style={{
          backgroundImage: `url(https://reasontosmile.wpengine.com/wp-content/uploads/2020/06/happiness-inside-2.jpg)`
        }}/>
            </div>
        </div>
      </section>
    );
  }
}
