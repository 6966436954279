import React, { Component } from "react";
import { Link } from "gatsby";

class BenefitText1 extends Component {
  render () {
    return (
      <div {...this.props}>
        <h4 className="has-text-weight-bold">
          <span className="highlightable"><span>B12 + Ginseng + 5-HTP</span></span>
        </h4>
        <br/>
        <h2>Ingredients to keep you crisp, clear and in the zone</h2>
        <p>All the benefits of 100% organic CBD, plus vitamin B12 for increased energy, ginseng for mental clarity and 5-HTP for improved mood.</p>
        <br />
        <Link to="/shop/energize">
          <button className="is-uppercase button is-primary is-fullwidth-mobile">SHOP ENERGIZE</button>
        </Link>
      </div>
    );
  }
}

export default BenefitText1;
