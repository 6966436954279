import React, { Component } from "react";
import SEO from "../components/seo";
import LayoutFullpage from "../components/layout-fullpage";
import Header from "../components/header";
import Footer from "../components/footer";
import ShopSectionBg from "../components/home/shop-section/shop-section-bg";
import "../components/home/shop-section/shop-section-hero.scss";
import ShopSectionHero1 from "../components/home/shop-section/shop-section-hero-1";
import ShopCategorySection from "../components/home/shop-section/shop-category-section";
import SmilePromiseSection from "../components/home/smile-promise-section";
import HappinessInsideSection from "../components/home/happiness-inside-section";
import EffectsSection from "../components/home/effects-section";
import ReasonsToSmileSection from "../components/home/reasons-to-smile-section";
import TestimonialsSection from "../components/home/testimonials-section";
import InstaFeedSection from "../components/home/insta-feed-section";
import QuickATCSection from "../components/home/quick-atc-section";
import QuickATCSectionMobile from "../components/home/quick-atc-section-mobile";
import LogosSection from "../components/home/logos-section";
import CustomerReviewSection from "../components/home/customer-review-section";
import yotpo from "../lib/yotpo";
import NotifyMeModal from "../components/product/notify-me-modal";
import _sortBy from "lodash.sortby";

// home
// import CountdownPage from "../components/countdown-page"; // todo countdown delete

export default class IndexPage extends Component {
  constructor (props) {
    super(props);
    const products = props.data.allWcProducts.edges;
    this.state = {
      products: products,
      isCartModalOpen: false,
      newCartItem: null,
      localCart: {},
      allProductBottomLine: []
    };
    this._shopBg = React.createRef();
    this._effectsSection = React.createRef();
    this._shopCategory = React.createRef();
    this.onSectionLeave = this.onSectionLeave.bind(this);
    this.afterSectionLoad = this.afterSectionLoad.bind(this);
    this._notifyMeModal = React.createRef();
  }

  componentDidMount () {
    if(this.state.allProductBottomLine.length === 0){
      yotpo.getAllProductsBottomLine()
      .then(allProductBottomLine => {
        //const amendedAllProductBottomLine = allProductBottomLine.concat([{domain_key: "6466", product_score: 5, total_reviews: 1},{domain_key: "6471", product_score: 5, total_reviews: 1}])
        this.setState({allProductBottomLine:allProductBottomLine})
      })
    }
  }

  componentWillUnmount () {
    // Remove these elements from DOM: header#masthead, #effects-section
    // cause it was moved to body append by fullpage plugin
    document.querySelector("header#masthead").remove();
    document.getElementById("effects-section").remove();
    console.log("fixed elements removed");

  }

  handleScrollTo = (elRef) => {
    const el = elRef.current ? elRef.current : elRef
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
  notifyMeClick = () => {
    this._notifyMeModal.current.open();
  }
  handleCartModalClose = () => {
    this.setState({ isCartModalOpen: false })
  }
  handleCartModalOption = () => {
    this.setState({ isCartModalOpen: true })
  }
  addNewCartItem = (localCart, item) => {
    this.setState({ newCartItem: item, localCart: localCart })
  }

  render () {
    //const sortedProducts = _sortBy(this.state.products, [function(p) { return p.node.slug.indexOf("gummies") !== -1; }])

    return (
      <div className="is-overflow-hidden">
      <LayoutFullpage isCartModalOpen={this.state.isCartModalOpen} handleCartModalClose={this.handleCartModalClose} newCartItem={this.state.newCartItem} localCart={this.state.localCart}>
        <SEO title="CBD Products" description="Explore our pure and potent 100% CBD products with no THC with allergen and gluten-free clean ingredients. Shop our cbd oil, topicals and gummies at ReasontoSmile.com today." />
        <ShopSectionBg ref={this._shopBg} />
        <Header isAlternate={true} />
        <section id="shop-section-hero-1" className="home-section">
          <div className="hero is-fullheight shop-section-hero">
            <ShopSectionHero1 onScrollButtonClick={() => { this.handleScrollTo(this._shopCategory) }} products={this.state.products}/>
            <LogosSection/>
          </div>
        </section>
        <div className="home-section fp-auto-height has-white-background">
          <TestimonialsSection />
          <QuickATCSection products={this.state.products} handleCartModalOption={this.handleCartModalOption} addNewCartItem={this.addNewCartItem} allProductBottomLine={this.state.allProductBottomLine} notifyMeClick={this.notifyMeClick} />
          <QuickATCSectionMobile products={this.state.products} handleCartModalOption={this.handleCartModalOption} addNewCartItem={this.addNewCartItem} allProductBottomLine={this.state.allProductBottomLine} notifyMeClick={this.notifyMeClick} />
          <CustomerReviewSection products={this.state.products}/>
          <EffectsSection />
          <ReasonsToSmileSection />
          <HappinessInsideSection />
          <SmilePromiseSection />
          <InstaFeedSection />
          <Footer />
        </div>
        <NotifyMeModal ref={this._notifyMeModal} />
      </LayoutFullpage>
      </div>
    )
  }

  onSectionLeave (origin, destination, direction) {
    const effectsSection = document.getElementById("effects-section");

    if (origin.item.id === "shop-section-hero-1" && direction === "down") {
      this._shopBg.current.handleSecondSlideViewport();

      // hide fixed header
      document.querySelector("header#masthead").style.transform = "translateY(-100vh)";

    } else if (destination.item.id === "shop-section-hero-1" && direction === "up"){
      

        // show fixed header
        document.querySelector("header#masthead").style.transform = "none";

      
    } 

  }

  afterSectionLoad (origin, destination, direction) {
    //
  }
}
export const pageQuery = graphql `
  query {
      allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}) {
      edges {
        node {
          id
          type
          wordpress_id
          slug
          stock_status
          name
          price
          stock_status
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          description
          grouped_products_nodes {
            id
            wordpress_id
            sku
            name
            price
            regular_price
            type
            slug
            images {
                id
                alt
                src
            }
            categories {
                wordpress_id
                name
            }
          }
        }
      }
    }
  }
`