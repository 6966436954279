import React, { Component } from "react";
import { Link } from "gatsby";

class BenefitText2 extends Component {
  render () {
    return (
      <div {...this.props}>
        <h4 className="has-text-weight-bold">
          <span className="highlightable"><span>Multivitamin + 5-HTP</span></span>
        </h4>
        <br/>
        <h2>Everything you need to help maintain a sense of balance</h2>
        <p>Our CBD gummies are the first of its kind! Not only are they vegan, they are also packed with 9 essential vitamins and minerals.</p>
        <br />
        <Link to="/shop/balance">
          <button className="is-uppercase button is-primary is-fullwidth-mobile">SHOP BALANCE</button>
        </Link>
      </div>
    );
  }
}

export default BenefitText2;
