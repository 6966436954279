import React, { Component } from "react";
import "./accordion.scss";
import anime from "animejs/lib/anime.es.js";

export default class DirectAnimatedDivider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    anime.remove("." + this.props.className);

    anime({
      targets: "." + this.props.className,
      width: "100%",
      easing: "easeInOutQuad",
      duration: 1500,
    });
  }

  render() {
    return (
      <hr
        id={this.props.id}
        className={this.props.className}
        style={{
          height: "1px",
          width: "0%",
          backgroundColor: this.props.color,
        }}
      />
    );
  }
}
