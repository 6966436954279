import React, { Component } from "react";
import Rating from "react-rating";
import yotpo from "../../lib/yotpo";
import Slider from "react-slick";
import _shuffle from "lodash.shuffle";
import CustomerReview from "./customer-review";
import WatchedElement from "../../components/watchedElement";
import "./customer-review-section.scss";
class CustomerReviewSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [
        {created_at: "01/11/2021", name: "Jose M.", score: 5, title: "Works for scaring", content: "Significantly reduced redness and scarring in my face after only a couple weeks of using it."},
        {created_at: "01/14/2021", name: "Josh J.", score: 5, title: "Loving it", content: "So far this lotion provides me tons and tons of relief for my joint pain and minor back aches."},
        {created_at: "07/31/2020", name: "Ronald J.", score: 5, title: "Recharged", content: "Wake up feeling new every morning after I take this oil"},
        {created_at: "07/29/2020", name: "Jimi S.", score: 5, title: "Locked in and ready", content: "I take one of these on my way to work and they do wonders. The rest of the day I'm locked and focused working at maximum productivity. These things are a godsend."},
        {created_at: "04/17/2020", name: "Jimena M.", score: 5, title: "Mi amor!", content: "I love love love these!!!! A great way to lock in and start the day with a rejuvenated spirit and focus"},
        {created_at: "04/23/2020", name: "Amelia P.", score: 5, title: "Lights out", content: "My husband and I bought this dropper for sleep. We have added it to our daily quarantine routine to keep a sense of order and sanity in the house. Not only is it a nightly activity to look forward to, but an effect that also has you waking up calm and refreshed."},
        {created_at: "05/30/2020", name: "Roy W.", score: 5, title: "Great sleep", content: "Best sleep i've gotten in years. And consistent!"},
        {created_at: "06/28/2020", name: "Jeff H.", score: 5, title: "Just what I need", content: "Helps so much with my overall productivity and mood. I feel happier and and more comfortable in all situations surprisingly."},
        {created_at: "08/27/2020", name: "Hudson L.", score: 5, title: "Confident", content: "gives me a new sense of confidence in presentations and intense conversations. Focusing more on the details rather than my worries."},
        {created_at: "10/01/2020", name: "Sean C.", score: 5, title: "Blown Away by the quality of this product!", content: "I've received the gummies VERY quickly, and I have to say I am absolutely impressed by the Smile packaging and the product quality overall. The gummies taste great and I'm blown away -- This is great stuff and I can't help but smile!"},
        {created_at: "01/11/2021", name: "Mary J.", score: 5, title: "Very soothing rub", content: "Wow this stuff is legit. Soothes pain on contact and continues working throughout the day/night on the inflammation."},
      ],
    };
  }
  componentDidMount() {
    /*
    yotpo.getAllReviews().then(reviews => {
      const sortedReviews = reviews.filter(function(review){
        return review.score > 4 && review.reviewer_type === "verified_buyer"
      }).sort(function compare(a, b) {
        return 0.5 - Math.random();
      }).slice(0,9);
      this.setState({ reviews: sortedReviews });
    });
    */
  }
  render() {
    const handleEnterViewport = () => {
      this.slider.slickPlay();
    };
    const reviewSettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 10000,
      autoplay: false,
      fade: false,
      slidesToShow: 3.1,
      slidesToScroll: 3,
      className: "has-white",
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    let avartarIndexs = [1,2,3,4,5,6,7,8];
    const sortReviews = _shuffle([0,1,2,3,4,5,6,7,8,9,10]).slice(0,9);
    return (
      <section className="section is-small has-white-background is-relative customer-review-section">
        <div className="container">
          <h1 className="is-h1-desktop-mobile has-text-centered">
            Customer Reviews
          </h1>
          
          {sortReviews.length > 0 ? (
            <WatchedElement handleEnterViewportScrollDown={handleEnterViewport}>
              <Slider ref={slider => (this.slider = slider)} {...reviewSettings}>
                {sortReviews.map((index, key) => {
                  var avatarIndex = Math.floor(Math.random() * 8) + 1;
                  if(avartarIndexs.length > 0){
                    var randomIndex = Math.floor(Math.random() * avartarIndexs.length);
                    avatarIndex = avartarIndexs[randomIndex];
                    avartarIndexs.splice(randomIndex, 1);
                  }
                  return <CustomerReview key={key} review={this.state.reviews[index]} avatar={avatarIndex}/>
                  })}
              </Slider>
            </WatchedElement>
          ) : (
            false
          )}
        </div>
      </section>
    );
  }
}

export default CustomerReviewSection;
