import React, { Component, Fragment } from "react";
import "./effects-section.scss";

import anime from "animejs/lib/anime.es.js";
import { isBrowser } from "react-device-detect";
import BenefitText1 from "./effects-section/benefit-text-1";
import BenefitText2 from "./effects-section/benefit-text-2";
import BenefitText3 from "./effects-section/benefit-text-3";
import BenefitText4 from "./effects-section/benefit-text-4";

const blobState = {
  happy: {
    1: ["M", 210, 167.3, "C", 91, 141, 31.4, 155.4, 13.4, 190.3, "C", -21, 257, 22, 315, 26, 378, "C", 28.3, 413.7, -9.299999999999997, 467.5, 19.2, 532.9, "C", 59, 624, 181, 649, 232.1, 645.3, "C", 421.7, 631.4, 325, 696, 449.1, 721.4, "C", 514.8000000000001, 734.8, 587.1, 699, 585.3, 650.1999999999999, "C", 577, 434, 668.5, 490.5, 741.7, 369.9, "C", 772, 320, 752.4000000000001, 158.29999999999998, 715.1, 139.89999999999998, "C", 657, 111, 589.3, 15, 513, 1.3, "C", 440.3, -11.7, 401, 46, 355.7, 97.7, "C", 289.8, 172.7, 250.1, 176.2, 210, 167.3, "Z"].join(" "),
    2: ["M", 87, 19.2, "C", 30.037787999999995, 62.942662999999996, 69.225258, 154.190657, 58.5, 190, "C", 63.557129, 254.90649399999998, 8.630078999999995, 330.6373709999999, 0, 390, "C", 2.3000000000000007, 425.7, -6.689621999999999, 514.602769, 50, 570, "C", 112.38248999999999, 656.729543, 199.81211199999998, 659.153287, 240, 660, "C", 394.13563600000003, 656.10277, 355.908308, 677.02730155, 450, 683.43182255, "C", 519.287011, 680.6902695499999, 546.2013190000001, 665.4844240000001, 600, 640, "C", 692.6931560000002, 595.6715260000001, 676.475292, 532.1753429999999, 660, 460, "C", 638.540127, 365.91990100000004, 603.2233890000001, 376.55322099999995, 600, 290, "C", 615.9105930000001, 215.013523, 665.066618, 126.07370800000004, 500, 80, "C", 427.3, 67, 364.540768, 67.224667, 290, 50, "C", 204.25805000000003, 44.587886999999995, 180.02101099999996, -32.17129200000002, 87, 19.2, "Z"].join(" "),
    3: ["M", 78, 39.2, "C", 46.14686899999999, 82.942663, 61.682241, 173.259931, 50.956983, 209.069274, "C", 42.142736000000006, 273.975767, -5.241297000000006, 273.76472899999993, 0, 390, "C", 2.3000000000000007, 425.7, 3.977324999999999, 463.00372799999997, 49.569846, 514.239546, "C", 120.27516099999998, 577.3877500000001, 186.93556999999998, 567.4911989999999, 227.123458, 568.337912, "C", 390.969057, 556.117856, 403.52359799999994, 573.030492, 472.646813, 603.016352, "C", 534.9981359999999, 615.5333119999999, 571.4332680000001, 621.5650880000001, 625.231949, 596.080664, "C", 709.6022790000001, 551.75219, 707.0787839999999, 508.4845809999994, 716.58243, 444.432472, "C", 714.9591729999999, 288.54496100000006, 684.3397040000002, 326.1395229999999, 651.587563, 249.296265, "C", 639.6237560000001, 109.92576, 715.356654, 44.97660700000004, 560, 10, "C", 472.04148599999996, -12.709963, 382.83842999999996, 17.555622, 304.803164, 45.387038, "C", 204.713167, 74.05153600000003, 147.70543499999997, -3.203763000000018, 78, 39.2, "Z"].join(" "),
    4: ["M", 179, 9.2, "C", 230.355853, 9.24458898, 309.875562, 37.4341291, 344.044833, 25.2037863, "C", 380.336429, 12.213791, 410.175608, 3.30636566, 438.690433, 23.7192154, "C", 467.205259, 44.1320651, 489.794926, 76.421482, 561.637474, 89.78262, "C", 633.480021, 103.143758, 584.967785, 177.00116, 630.517442, 246.404849, "C", 676.067098, 315.808538, 650.258222, 365.86347, 609.936323, 383.727656, "C", 523.651202, 421.955357, 545.500224, 441.254778, 509.208628, 477.626765, "C", 472.917031, 513.998752, 399.495732, 535.334932, 344.044833, 491.730188, "C", 297.628642, 455.230039, 296.643564, 477.626765, 239.24359, 477.626765, "C", 130.218977, 477.626765, 43.7046804, 398.103637, 23.3456251, 379.273943, "C", 3.03593491, 360.490398, -5.16920046, 336.59253, 3.71853739, 298.735973, "C", 13.6897179, 256.264584, 66.7384639, 269.025514, 91.4849487, 230.445712, "C", 111.482359, 199.269723, 24.4565924, 160.67088, 40.0101336, 89.78262, "C", 55.5636748, 18.89436, 128.887512, 9.24458898, 179.621682, 9.24458898,"Z"].join(" ")
  },
  sunny: {
    1: "M207.980195,34.4897157 C170.421903,6.32473416 132.504201,-13.4950677 106.447012,11.2559767 C57.0282062,58.1976126 79.0420379,170.573044 48.0429688,232.213576 C33.0376223,262.180358 2.66751975,297.55254 0.421210394,331.312585 C-4.34096544,404.617402 31.8695414,439.230932 79.0420379,491.10381 C130.437596,547.718268 333.324257,591.625293 405.655418,613.910716 C477.98658,636.196139 540.883242,595.892714 531.898004,516.234181 C522.912767,436.575647 626.602407,416.471351 648.706091,346.011481 C670.809775,275.551611 660.3869,139.27862 618.156284,94.7077738 C575.925668,50.1369276 543.488961,34.0155577 497.30484,18.3683458 C357.135136,-29.2371112 314.005997,113.958586 207.980195,34.4897157 Z",
    2: "M189.867905,44.8105367 C85.56565,-0.130152308 51.2749004,34.1484499 25.8565737,58.0863197 C-22.3505976,103.485728 21.100434,184.625343 34.5615767,225.064596 C44.5834207,266.948536 31.6182738,290.856503 26.3446266,317.701629 C-10.0402127,389.4957 -17.288906,503.44116 68.1868508,507.831097 C218.856951,512.355578 261.361511,551.905068 331.538262,601.183746 C429.546341,649.665649 543.60706,594.746354 545.993808,502.445367 C561.247889,354.491838 726.878091,430.561365 629.20256,254.701798 C604.441916,183.863963 641.231825,100.331549 525.406076,89.5394186 C478.206102,75.1568017 486.209371,48.1999052 435.152823,7.93336225 C330.159176,-23.7461119 277.787681,58.2397844 189.867905,44.8105367 Z",
    3: "M228.841156,58.6901843 C183.896456,79.4711529 149.998996,56.0597018 84.9688188,76.5055338 C-21.1624207,158.561123 125.909246,187.740601 56.7727326,274.25591 C41.4125004,297.979554 21.0444666,309.206463 6.88734862,344.626539 C-10.8062196,369.413456 3.83934543,487.605572 90.752631,483.586263 C201.418311,478.821758 258.388441,565.298078 337.287641,612.747545 C452.33567,642.381663 468.382698,565.31013 524.538573,482.695496 C567.371347,445.933812 726.335365,485.509346 628.647199,257.331328 C605.323395,145.040788 642.048399,113.504583 536.829174,88.9762782 C490.17105,72.4648807 462.420395,19.5314102 424.04483,4.35336864 C353.121766,-13.320115 338.63,32.4472125 228.841156,58.6901843 Z",
    4: "M291.342322,68.6676052 C241.176054,59.708837 145.461575,113.547287 130.412089,144.755582 C97.7231363,212.543052 165.930205,238.960216 90.1795319,306.615461 C51.4556941,341.200905 21.4209028,343.154916 4.68534516,394.693059 C-21.9687247,476.775845 73.583601,524.273189 177.685345,557.475215 C278.471028,589.619622 278.982771,557.936354 393.935345,604.050279 C475.770342,636.878887 571.461508,612.350785 632.313253,556.552936 C693.164997,500.755087 635.833602,394.693059 611.19116,306.615461 C594.032434,245.286337 663.996392,152.594949 649.41209,100.947353 C634.827787,49.2997566 611.694066,8.25836294 557.380113,1.80241337 C424.847682,-13.9508625 425.618485,92.6468465 291.342322,68.6676052 Z"
  },
  color: {
    1: "#fbdc00",
    2: "#951a4b",
    3: "#f1ebbf",
    4: "#FF7F40"
  }

};

const SvgEffectsBlobHappy = props => (
  <svg width={879} height={707} {...props}>
    <path d={blobState.happy[1]} fill="#3AC1CC" fillRule="evenodd" className="happy-blob-effects" />
  </svg>
);

const SvgEffectsBlobSunny = props => (
  <svg width={660} height={620} {...props}>
    <path d={blobState.sunny[1]} fill="#fbdc00" fillRule="evenodd" className="sunny-blob-effects" />
  </svg>
);

class EffectsSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      position: 1
    }
    this.handleViewport = this.handleViewport.bind(this);
  }

  onTabClick (num) {
    this.props.onTabClick && this.props.onTabClick(num);
  }

  onArrowClick (direction) {
    if(direction === "left"){
      var num = this.state.position - 1;
      if(this.state.position === 1){
        num = 4;
      }
      this.handleViewport(num);
      this.setState({position: num});
    }else{
      if(direction === "right"){
        num = this.state.position + 1;
        if(this.state.position === 4){
          num = 1;
        }
        this.handleViewport(num);
        this.setState({position: num});
      }
    }

  }

  handleViewport (num) {

    console.log("handle effects viewport", num);

    const section = document.getElementById("effects-section");

    // TOGGLE TAB ACTIVE CLASS
    const tabs = section.querySelectorAll("#effects-tabs .tab-item");
    tabs.forEach(el => {
      el.id === `effects-tab-${num}` ? el.classList.add("is-active") : el.classList.remove("is-active");
    });

    // TOGGLE TEXT ACTIVE CLASS
    const textEls = section.querySelectorAll(".benefit-text");
    textEls.forEach(el => {
      el.id === `benefit-text-${num}` ? el.classList.add("is-active") : el.classList.remove("is-active");
    });

    // ANIMATE HAPPY BLOB
    const happyBlob = section.querySelector(".happy-blob-effects");
    if (happyBlob) {
      if (this.happyBlobAnimation) this.happyBlobAnimation.pause();
      anime.remove(happyBlob);
      this.happyBlobAnimation = anime({
        targets: happyBlob,
        d: [{ value: blobState.happy[num] }],
        elasticity: 0,
        easing: "easeOutElastic",
        duration: 1000
      });
    }

    // ANIMATE SUNNY BLOB
    const sunnyBlob = section.querySelector(".sunny-blob-effects");
    if (sunnyBlob) {
      if (this.sunnyBlobAnimation) this.sunnyBlobAnimation.pause();
      anime.remove(sunnyBlob);
      this.sunnyBlobAnimation = anime({
        targets: sunnyBlob,
        d: [{ value: blobState.sunny[num] }],
        elasticity: 0,
        easing: "easeOutElastic",
        fill: blobState.color[num],
        duration: 1000
      });
    }

    // TOGGLE SLIDE
    const slides = section.querySelector("#benefits-slides");
    if (slides) {
      slides.classList.remove("show-slide-1");
      slides.classList.remove("show-slide-2");
      slides.classList.remove("show-slide-3");
      slides.classList.remove("show-slide-4");
      slides.classList.add("show-slide-" + num);
    }

    this.setState({position: num});
  }

  render () {
    return (
      <Fragment>
        <section id="effects-section" className="is-relative">
          <h1 className="is-h1-desktop-mobile has-text-centered effects-section-title">Explore our effects</h1>
          <div className="tabs is-toggle" id="effects-tabs">
            <div className="tab-arrow">
              <i className="fal fa-chevron-left" onClick={() => this.onArrowClick("left")}></i>
            </div>
            <div className="effects-tab-item">
            <ul>
              <li id="effects-tab-1" onClick={() => this.handleViewport(1)} className="tab-item is-active"><a>
                {this.state.position === 1 ?
                        <img src={require(`../../images/effects/energize-thin-white.svg`)} alt=""/>
                        :
                        <img src={require(`../../images/effects/energize-thin-yellow.svg`)} alt=""/>
                    } 
                Energize
                </a>
              </li>
              <li id="effects-tab-2" onClick={() => this.handleViewport(2)} className="tab-item"><a>
              {this.state.position === 2 ?
                            <img src={require(`../../images/effects/balance-thin-white.svg`)} alt=""/>
                            :
                            <img src={require(`../../images/effects/balance-thin-yellow.svg`)} alt=""/>
                    }  
                Balance
                </a>
              </li>
              <li id="effects-tab-3" onClick={() => this.handleViewport(3)} className="tab-item"><a>
              {this.state.position === 3 ?
                        <img src={require(`../../images/effects/unwind-thin-white.svg`)} alt=""/>
                        :
                        <img src={require(`../../images/effects/unwind-thin-yellow.svg`)} alt=""/>
                    }
                Unwind
                </a>
              </li>
              <li id="effects-tab-4" onClick={() => this.handleViewport(4)} className="tab-item"><a>
              {this.state.position === 4 ?
                         <img src={require(`../../images/effects/rejuvenate-thin-white.svg`)} alt=""/>
                         :
                         <img src={require(`../../images/effects/rejuvenate-thin-yellow.svg`)} alt=""/>
                    }
                Rejuvenate
                </a>
              </li>
            </ul>
            </div>
            <div className="tab-arrow">
              <i className="fal fa-chevron-right" onClick={() => this.onArrowClick("right")}></i>
            </div>
          </div>
          <section className="section is-small effectsSection-section">

            <div className="container is-relative">
              <div className="columns is-y-marginless is-reversed-mobile">
                <div className="column column-texts is-5 has-text-centered-mobile">
                  <BenefitText1 id="benefit-text-1" className="benefit-text is-active" />
                  <BenefitText2 id="benefit-text-2" className="benefit-text" />
                  <BenefitText3 id="benefit-text-3" className="benefit-text" />
                  <BenefitText4 id="benefit-text-4" className="benefit-text" />
                </div>
                <div className="column column-images is-6 is-offset-1">
                  <div id="benefits-svg-container">
                    <SvgEffectsBlobSunny />
                    <div id="benefits-slides" className="slides">
                      <div className="slide" id="benefits-slide-1">
                        <img id="benefits-image-1" data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/tincture-zesty-lemon_2x-1.png" alt="" className="lazyload" />
                      </div>
                      <div className="slide" id="benefits-slide-2">
                        <img id="benefits-image-2" data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/gummies_2x.png" alt="" className="lazyload" />
                      </div>
                      <div className="slide" id="benefits-slide-3">
                        <img id="benefits-image-3" data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2019/11/Smile_Tincture_product_floating_box_mint@2x.png" alt="" className="lazyload" />
                      </div>
                      <div className="slide" id="benefits-slide-4">
                        <img id="benefits-image-4" data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2020/08/CBD_BODY_LOTION.png" alt="" className="lazyload" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </section>
       
      </Fragment>
    );
  }

}

export default EffectsSection;
