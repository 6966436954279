export default class SvgBlobsStates {

  // large screen resolution: 1596 x 940

  viewportNum = 1;

  /**
   * screen resolutions at the developer
   * if user screen resolutions is different then will be executed calculation based these values
   */
  devResolutions = {
    mobile: { width: 375, height: 667 },
    desktop: { width: 1440, height: 756 }
  };

  aspectRatio = {
    mobile: this.devResolutions.mobile.width / this.devResolutions.mobile.height,
    desktop: this.devResolutions.desktop.width / this.devResolutions.desktop.height
  };

  get deviceAspectRatio () {
    return document.documentElement.clientWidth / document.documentElement.clientHeight;
  }

  get isMobile () {
    // return window.matchMedia("screen and (max-width: 768px)").matches;
    return this.deviceAspectRatio <= 0.6;
  }

  get isTablet () {
    // return window.matchMedia("screen and (max-width: 1023px) and (min-width: 769px)").matches;
  }

  get isDesktop () {
    // return window.matchMedia("screen and (min-width: 1024px)").matches;
  }

  init () {

    const isMobile = this.isMobile;

    this.happy_blob_style = (() => {

      let transform;

      if (isMobile) {
        transform = this.mobileTransform(0.67, -180, 5.3);
      } else { // todo tablet
        transform = this.desktopTransform(1, -6, -13);
      }

      const styleOb = {};
      if (transform) {
        styleOb.transform = transform;
      }

      return styleOb;
    })();

    this.sunny_blob_states = {
      state_1: (() => {

        let transform;

        if (isMobile) {
          transform = this.mobileTransform(0.63, -129, 9, false);
        } else {
          transform = this.desktopTransform(1, -6, -12, false);
        }

        return {
          // by https://app.svgator.com
          d: ["M", 77.3, 328.771, "C", 10.799999999999997, 376.651, -42.400000000000006, 475.071, 48.03999999999999, 608.071, "C", 177.927, 799.086, 396.5, 722.1310000000001, 593.3399999999999, 788.951, "C", 734.3199999999999, 836.831, 736.9799999999999, 959.191, 1042.8799999999999, 948.551, "C", 1133.32, 945.412, 1253.339, 929.426, 1292.9199999999998, 871.4110000000001, "C", 1372.7199999999998, 754.3710000000001, 1072.1399999999999, 608.0710000000001, 1194.4999999999998, 435.17100000000005, "C", 1308.8799999999997, 273.629, 1585.5199999999998, 350.05100000000004, 1596.1599999999999, 227.69100000000006, "C", 1607.598, 95.88800000000006, 1234.3999999999999, 9.571000000000055, 1088.1, 1.591000000000065, "C", 919.9879999999999, -7.585999999999935, 750.28, 52.131000000000064, 651.8599999999999, 118.63100000000007, "C", 587.7799999999999, 161.93600000000006, 519.6309999999999, 194.65400000000005, 441.7199999999999, 235.67100000000008, "C", 239.55999999999992, 342.0710000000001, 166.30299999999994, 264.6910000000001, 77.2999999999999, 328.7710000000001, "Z"].join(" "),
          ...transform
        };

      })(),

      state_2: (() => {

        let transform;

        if (isMobile) {
          transform = this.mobileTransform(0.63, -58, 7, false);
        } else {
          transform = this.desktopTransform(1, 12, -11, false);
        }

        return {
          // by https://app.svgator.com
          d: ["M", 63.415368, 351.03772160221155, "C", 100.49902900000001, 443.75097696221127, -62.892331, 429.43343163275995, 31.72443, 626.2729153682319, "C", 93.1678269999999, 746.2296298269973, 169.34567400000012, 811.936671042867, 227.457704, 850.1012021586027, "C", 351.1128750000002, 928.5969445348039, 552.2208350000002, 949.0503473052997, 673.844738, 867.944887, "C", 734.3400990000001, 819.0031880843737, 759.053554, 839.4468170337864, 879.14242, 840.404954, "C", 1041.278196, 842.6470456177311, 802.7805550000002, 734.4592892819543, 984.37036, 525.5033228557842, "C", 1050.3390310000007, 430.16010164168245, 1078.25663223, 393.5016468476939, 1077.76759123, 283.27474021598874, "C", 1056.3749192300002, 176.98343796483672, 1025.613819, 125.6673707978769, 929.195057, 71.978098, "C", 704.537405, -24.570578448477114, 453.7830010000002, -5.8018222897132095, 322.315081, 34.423645, "C", 238.20603899999992, 58.82415260045293, 177.90371299999987, 89.83418711767689, 129.034825, 127.057964, "C", 38.96355100000001, 192.11456330176293, 46.328651000000036, 291.61114981974947, 64.25072, 350.19729699735603, "Z"].join(" "),
          ...transform
        };
      })(),
      state_3: (() => {

        let transform;

        if (isMobile) {
          transform = this.mobileTransform(0.61, -168.5, 3, false);
        } else {
          transform = this.desktopTransform(1, -14.7, -30, false);
        }

        return {
          // by https://app.svgator.com
          d: ["M", 65.054798, 481.007105, "C", 157.091384, 524.009056, 490.054126, 409.346727, 662.503302, 378.98039, "C", 878.241902, 342.501833, 761.7189760000002, 447.797379, 622.899341, 519.57445, "C", 165.98718100000008, 777.4405370000002, 1329.4168720000005, 1374.7802340000005, 1750.606909, 1213.30591, "C", 1871.7761799999998, 1155.6700250000001, 1931.243496, 967.6474559999995, 1781.305056, 824.3149, "C", 1611.1171309999997, 640.1413570000001, 1132.9723210000002, 804.9174170000003, 1228.503053, 446.651563, "C", 1265.7505909999995, 325.104173, 1186.492618, 257.813613, 1166.6246, 230.076978, "C", 1095.917337, 144.88494599999999, 989.6326580000002, 81.53527899999999, 872.371871, 49.299788, "C", 572.5525540000001, -33.047944, 346.9328999999998, 2.0704959999999937, 226.730721, 81.441783, "C", 173.21907099999993, 116.52513199999994, 147.42384499999983, 129.87831299999993, 80.196068, 209.164555, "C", -60.988322999999994, 403.36943299999984, 34.545603000000014, 477.90271500000006, 62.615773, 481.007105, "Z"].join(" "),
          ...transform
        };
      })()
    };

  };

  get sunny_blob_style () {
    const sunny_blob_initial_state = this.sunny_blob_states[`state_` + this.viewportNum];
    return {
      transform: [
        `scale(${sunny_blob_initial_state.scale})`,
        `translateX(${sunny_blob_initial_state.translateX})`,
        `translateY(${sunny_blob_initial_state.translateY})`
      ].join(" ")
    };
  }

  desktopTransform (scale, translateXPercent, translateYPercent, returnAsString = true) {
    scale = this.getDesktopScale(scale);
    const translateX = `${this.getDesktopTranslateXPercent(translateXPercent)}%`;
    const percentShift = 50 / scale + this.getDesktopTranslateYPercent(translateYPercent);
    const pixelShift = document.documentElement.clientWidth / this.aspectRatio.desktop / scale / 2;
    const translateY = `calc(${percentShift}% - ${pixelShift}px)`;

    if (returnAsString) {
      return `scale(${scale}) translateX(${translateX}) translateY(${translateY})`;
    }

    return { scale, translateX, translateY };
  };

  mobileTransform (scale, translateXPercent, translateYPercent, returnAsString = true) {
    scale = this.getMobileScale(scale);
    const translateX = `${this.getMobileTranslateXPercent(translateXPercent)}%`;
    const percentShift = 50 / scale + this.getMobileTranslateYPercent(translateYPercent);
    const pixelShift = document.documentElement.clientWidth / this.aspectRatio.mobile / scale / 2;
    const translateY = `calc(${percentShift}% - ${pixelShift}px)`;

    if (returnAsString) {
      return `scale(${scale}) translateX(${translateX}) translateY(${translateY})`;
    }

    return { scale, translateX, translateY };
  }

  /**
   * @param {number} devScale - beautiful mobile scale for developer
   * @return {number} - returns beautiful mobile scale for user
   */
  getMobileScale (devScale) {
    return document.documentElement.clientWidth * devScale / this.devResolutions.mobile.width;
  }

  /**
   * @param {number} devScale - beautiful desktop scale for developer
   * @return {number} - returns beautiful desktop scale for user
   */
  getDesktopScale (devScale) {
    return document.documentElement.clientWidth * devScale / this.devResolutions.desktop.width;
  }

  /**
   * @param {number} devTranslateXPercent - beautiful mobile translateX percent for developer
   * @return {number} - returns beautiful mobile translateX percent for user
   */
  getMobileTranslateXPercent (devTranslateXPercent) {
    return this.devResolutions.mobile.width / document.documentElement.clientWidth * devTranslateXPercent;
  }

  /**
   * @param {number} devTranslateXPercent - beautiful desktop translateX percent for developer
   * @return {number} - returns beautiful desktop translateX percent for user
   */
  getDesktopTranslateXPercent (devTranslateXPercent) {
    return this.devResolutions.desktop.width / document.documentElement.clientWidth * devTranslateXPercent;
  }

  /**
   * @param {number} devTranslateYPercent - beautiful mobile translateY percent for developer
   * @return {number} - returns beautiful mobile translateY percent for user
   */
  getMobileTranslateYPercent (devTranslateYPercent) {
    return this.devResolutions.mobile.height / document.documentElement.clientHeight * devTranslateYPercent;
  }

  /**
   * @param {number} devTranslateYPercent - beautiful desktop translateY percent for developer
   * @return {number} - returns beautiful desktop translateY percent for user
   */
  getDesktopTranslateYPercent (devTranslateYPercent) {
    return this.devResolutions.desktop.height / document.documentElement.clientHeight * devTranslateYPercent;
  }

}

