import React, { Component } from "react";
import anime from "animejs";
import ShopSectionImages from "./shop-section-images";
import SvgBlobsStates from "./svg-blobs-states";
import SvgBlobs from "./svg-blobs";
import "./shop-section-bg.scss";

export default class ShopSectionBg extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isMounted: false
    };
    this.svgBlobsStates = new SvgBlobsStates();
    this.getSelectors = this.getSelectors.bind(this);
    this.handleFirstSlideViewport = this.handleFirstSlideViewport.bind(this);
    this.handleSecondSlideViewport = this.handleSecondSlideViewport.bind(this);
    this.handleThirdSlideViewport = this.handleThirdSlideViewport.bind(this);
  }

  componentDidMount () {
    this.svgBlobsStates.init();
    requestAnimationFrame(() => {
      this.setState({ isMounted: true });
    });
  }

  render () {
    return (
      <div id="home-svg-background-container" className="svg-background-container has-primary-background">
        

      </div>
    );
  }

  getSelectors () {
    const shopSectionBg = document.getElementById("home-svg-background-container");
    const svg = shopSectionBg.querySelector("svg#shop-section-bg-svg");
    const sunnyBlob = svg.querySelector("path#shop-section-sunny-blob");
    const slide1Images = shopSectionBg.querySelectorAll(".slide1-img");
    const slide2Images = shopSectionBg.querySelectorAll(".slide2-img");
    const slide3Images = shopSectionBg.querySelectorAll(".slide3-img");
    return {
      shopSectionBg,
      sunnyBlob,
      slide1Images,
      slide2Images,
      slide3Images
    };
  }

  handleFirstSlideViewport () {
    this.svgBlobsStates.viewportNum = 1;
    const { sunnyBlob, slide1Images, slide2Images } = this.getSelectors();
    anime.remove([sunnyBlob, slide1Images, slide2Images]);

    anime({
      targets: sunnyBlob,
      d: [{ value: this.svgBlobsStates.sunny_blob_states.state_1.d }],
      scale: this.svgBlobsStates.sunny_blob_states.state_1.scale,
      translateX: this.svgBlobsStates.sunny_blob_states.state_1.translateX,
      translateY: this.svgBlobsStates.sunny_blob_states.state_1.translateY,
      duration: 1000,
      elasticity: 1000,
      easing: "linear",
      loop: false,
      direction: "alternate"
    });
    anime({
      targets: slide1Images,
      translateX: 0,
      translateY: 0,
      easing: "linear",
      duration: 1000,
      loop: false
    });
    anime({
      targets: slide2Images,
      translateX: "100vw",
      translateY: "-100vh",
      easing: "linear",
      duration: 1000,
      loop: false
    });
  }

  handleSecondSlideViewport () {
    this.svgBlobsStates.viewportNum = 2;
    const { sunnyBlob, slide1Images, slide2Images, slide3Images } = this.getSelectors();
    anime.remove([sunnyBlob, slide1Images, slide2Images, slide3Images]);

    anime({
      targets: sunnyBlob,
      d: [{ value: this.svgBlobsStates.sunny_blob_states.state_2.d }],
      scale: this.svgBlobsStates.sunny_blob_states.state_2.scale,
      translateX: this.svgBlobsStates.sunny_blob_states.state_2.translateX,
      translateY: this.svgBlobsStates.sunny_blob_states.state_2.translateY,
      duration: 1000,
      elasticity: 1000,
      easing: "linear",
      loop: false,
      direction: "alternate"
    });
    anime({
      targets: slide1Images,
      translateX: "-100vw",
      translateY: "100vh",
      easing: "linear",
      duration: 1000,
      loop: false
    });
    anime({
      targets: slide2Images,
      translateX: 0,
      translateY: 0,
      easing: "linear",
      duration: 1000,
      loop: false
    });
    anime({
      targets: slide3Images,
      translateX: "100vw",
      translateY: "-100vh",
      easing: "linear",
      duration: 1000,
      loop: false
    });
  }

  handleThirdSlideViewport () {
    this.svgBlobsStates.viewportNum = 3;
    const { sunnyBlob, slide2Images, slide3Images } = this.getSelectors();

    anime.remove([sunnyBlob, slide2Images, slide3Images]);

    anime({
      targets: sunnyBlob,
      d: [{ value: this.svgBlobsStates.sunny_blob_states.state_3.d }],
      scale: this.svgBlobsStates.sunny_blob_states.state_3.scale,
      translateX: this.svgBlobsStates.sunny_blob_states.state_3.translateX,
      translateY: this.svgBlobsStates.sunny_blob_states.state_3.translateY,
      duration: 1000,
      elasticity: 1000,
      easing: "linear",
      loop: false,
      direction: "alternate"
    });
    anime({
      targets: slide2Images,
      translateX: "-100vw",
      translateY: "100vh",
      easing: "linear",
      duration: 1000,
      loop: false
    });
    anime({
      targets: slide3Images,
      translateX: 0,
      translateY: 0,
      easing: "linear",
      duration: 1000,
      loop: false
    });
  }
}
